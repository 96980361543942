<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    bottom
    offset-y
    max-width="400"
    z-index="10000"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="rangeFormatted"
        :label="label"
        clearable
        readonly
        dense
        v-bind="attrs"
        v-on="on"
        @click:clear="range = [];"
      >
        <template #prepend-inner>
          <v-icon
            small
            class="mt-1"
            color="grey"
          >
            {{ icon }}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <v-range-slider
          v-model="range"
          class="mt-1 mb-n2"
        >
          <template #prepend>
            <v-text-field
              :value="range[0]"
              label="From"
              :hint="range[0] | pluralize(item)"
              persistent-hint
              dense
              type="number"
              class="mb-0"
              @change="$set(range, 0, $event);"
            />
          </template>
          <template #append>
            <v-text-field
              :value="range[1]"
              label="To"
              :hint="range[1] | pluralize(item)"
              persistent-hint
              dense
              type="number"
              class="mb-0"
              @change="$set(range, 1, $event)"
            />
          </template>
        </v-range-slider>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [1, null],
    },
    label: {
      type: String,
      default: 'Range',
    },
    item: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'fal fa-filter',
    },
  },
  data () {
    return {
      menu: false,
      range: this.value,
    }
  },
  computed: {
    rangeFormatted () {
      if (this.range[1] != null) {
        return `${this.range[0]} - ${this.range[1]}`
      } else {
        return null
      }
    },
  },
  watch: {
    range () {
      this.$emit('update:value', this.range)
    },
  },
}
</script>
